/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable indent */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import classNames from 'classnames';
import React from 'react';
import { Filter, MapLocation } from '../types';
import Tooltip from '../../../atoms/tooltip/react/Tooltip/Tooltip';
import Button from '../../../atoms/button/react/Button';

export type Props = {
  locations?: Array<MapLocation & { index: number }>;
  onLocationChange: (index?: number) => void;
  activeIndex?: number;
  isDesktop?: boolean;
  containerHeight?: number;
  containerWidth?: number;
  activeFilter?: string;
  filterConfig?: Filter[];
};

const Locations = ({
  locations,
  onLocationChange,
  activeIndex,
  isDesktop,
  containerHeight = 0,
  containerWidth = 0,
  activeFilter,
  filterConfig,
}: Props): JSX.Element => {
  const cornerCoordinates = isDesktop
    ? {
        topLeft: { lat: 49.7, long: 7 },
        topRight: { lat: 49.7, long: 10.69 },
        bottomRight: { lat: 47.5, long: 10.69 },
        bottomLeft: { lat: 47.5, long: 7 },
      }
    : {
        topLeft: { lat: 49.7, long: 7.4 },
        topRight: { lat: 49.7, long: 10.4 },
        bottomRight: { lat: 47.55, long: 10.4 },
        bottomLeft: { lat: 47.55, long: 7.4 },
      };
  const sizeInPixel = {
    1: 8,
    2: 18,
    3: 24,
  };
  const calculatePosition = (lat: number, long: number, size: number) => {
    const x =
      ((long - cornerCoordinates.topLeft.long) /
        (cornerCoordinates.topRight.long - cornerCoordinates.topLeft.long)) *
      containerWidth;
    const y =
      ((lat - cornerCoordinates.topLeft.lat) /
        (cornerCoordinates.bottomLeft.lat - cornerCoordinates.topLeft.lat)) *
      containerHeight;
    const xTrans = x - sizeInPixel[size || 1] / 2;
    const yTrans = y - sizeInPixel[size || 1] / 2;
    return `translate(${xTrans}px, ${yTrans}px)`;
  };

  const getMarkerClassNames = (location: MapLocation & { index: number }) => {
    return classNames(
      'filter-map__marker',
      location.size && `filter-map__marker--size-${location.size}`,
      activeIndex === location.index && 'filter-map__marker--active',
    );
  };

  const getMarkerStyles = (
    location: MapLocation & { index: number },
  ): React.CSSProperties => {
    return {
      position: 'absolute',
      left: 0,
      top: 0,
      width: `${sizeInPixel[Number(location.size) || 1]}px`,
      height: `${sizeInPixel[Number(location.size) || 1]}px`,
      transform: calculatePosition(
        Number(location.lat),
        Number(location.long),
        Number(location.size),
      ),
    };
  };

  const getText = (location: MapLocation & { index: number }): JSX.Element => {
    const { filter: schools } = location;
    const filteredSchoolsKeys =
      activeFilter === 'all' || !activeFilter
        ? Object.keys(schools || {})
        : Object.keys(schools || {}).filter(s => s === activeFilter);
    return (
      <p>
        {filteredSchoolsKeys.map((schoolKey, i) => {
          const number = Number(location.filter?.[schoolKey]);

          const filter = filterConfig?.find(f => f.value === schoolKey);
          return (
            <span key={schoolKey}>
              {number} {number > 1 ? filter?.tooltipPlural : filter?.tooltip}
              {i < filteredSchoolsKeys.length - 1 ? <br /> : ''}
            </span>
          );
        })}
      </p>
    );
  };

  const renderTooltip = (
    location: MapLocation & { index: number },
  ): JSX.Element => (
    <div className="filter-map__location-subgrid">
      {location.name && (
        <h3 className="headline headline--4">{location.name}</h3>
      )}
      {location.filter && getText(location)}
      {location.link?.link && (
        <Button
          text={location.link?.text || 'Zum Programm'}
          link={location.link.link}
          appearance="secondary"
        />
      )}
    </div>
  );

  const handleToggleTooltip = (i: number) => {
    onLocationChange(activeIndex !== i ? i : undefined);
  };

  const isOpen = (index: number) => {
    return index === activeIndex;
  };

  const handleClose = () => {
    onLocationChange();
  };

  return (
    <div className="filter-map__markers">
      {isDesktop
        ? locations?.map(location => (
            <button
              className={getMarkerClassNames(location)}
              key={location.index}
              style={getMarkerStyles(location)}
              type="button"
              onClick={() => handleToggleTooltip(location.index)}
            >
              <Tooltip
                isOpen={isOpen(location.index)}
                className="filter-map__tooltip"
                content={renderTooltip(location)}
                wrapperClassName="filter-map__tooltip-wrap"
                onClose={handleClose}
                onToggle={() => {
                  // fixes tooltip not opening on very first click
                  return null;
                }}
              >
                <span className="u-hidden-visually">{location.name}</span>
              </Tooltip>
            </button>
          ))
        : locations?.map((location, i) => (
            <button
              key={`${location.index}-${i}`}
              className={getMarkerClassNames(location)}
              type="button"
              onClick={() => onLocationChange(location.index)}
              title={location.name}
              style={getMarkerStyles(location)}
            >
              <span className="u-hidden-visually">{location.name}</span>
            </button>
          ))}
    </div>
  );
};

export default Locations;
