import classnames from 'classnames';
import React from 'react';
import Icon from '../../../_particles/icon/react/Icon';

export interface Props
  extends React.ButtonHTMLAttributes<HTMLButtonElement & HTMLAnchorElement> {
  text: string;
  link?: string;
  icon?: string;
  appearance?: 'primary' | 'secondary' ;
  transparent?: boolean;
}

const Button: React.FC<Props> = ({
  text,
  link,
  icon,
  appearance = 'primary',
  transparent,
  ...rest
}: Props) => {
  const classNames = classnames(
    'button',
    `button--${appearance}`,
    transparent && `button--${transparent}`,
    rest.className,
  );

  let typeIs = rest.type;
  if (!rest.type && !link) {
    typeIs = 'button';
  }

  if (!link && !rest.onClick && !rest.onMouseDown && rest.type !== 'submit') {
    return (
      <div className={classnames('button', classNames)}>
        {text && <span className="button__text">{text}</span>}
        {icon && <div className="button__icon"><Icon icon={icon}/></div>}
      </div>
    );
  }
  return !link ? (
    <button {...rest} className={classNames} type={typeIs}>
      {icon && <div className="button__icon"><Icon icon={icon}/></div>}
      {text && <span className="button__text">{text}</span>}
    </button>
  ) : (
    <a href={link} className={classNames}>
      {icon && <div className="button__icon"><Icon icon={icon}/></div>}
      {text && <span className="button__text">{text}</span>}
    </a>
  );
};

export default Button;
