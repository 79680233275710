/* eslint-disable arrow-parens */
import React from 'react';
import ReactDom from 'react-dom';
import FilterMap from './react/FilterMap';

document.querySelectorAll('.filter-map').forEach($el => {
  const apiUrl = $el.getAttribute('data-url');
  const assetUrl = $el.getAttribute('data-asset-url') || '';
  const listLinkUrl = $el.getAttribute('data-listlinkurl') || '';

  if (apiUrl) {
    const init = async () => {
      const configRes = await fetch(`/${apiUrl}`);
      const configJson = await configRes.json();

      if (configJson) {
        ReactDom.render(
          <FilterMap
            config={{
              ...configJson,
              assetUrl,
              listLink: { ...configJson.listLink, link: listLinkUrl },
            }}
          />,
          $el,
        );
      }
    };

    init();
  }
});
