/* eslint-disable arrow-body-style */
import React from 'react';
import { Filter } from '../types';
import Button from '../../../atoms/button/react/Button';

export type Props = {
  onFilterChange: (filter: string) => void;
  filter: Filter[];
  activeFilter: string;
};

const LocationFilter = ({
  onFilterChange,
  filter,
  activeFilter,
}: Props): JSX.Element => {
  const allFilters = [{ text: 'Alle Schulen', value: 'all' }, ...filter];
  return (
    <div className="filter-map__filter">
      {allFilters.map(f => (
        <Button
          key={f.value}
          text={f.text || 'Filter'}
          onClick={() => onFilterChange(f.value || 'all')}
          appearance={activeFilter === f.value ? 'primary' : 'secondary'}
          className="filter-map__filter-button"
        />
      ))}
    </div>
  );
};

export default LocationFilter;
