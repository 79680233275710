import classnames from 'classnames';
import React from 'react';
import Icon from '../../_particles/icon/react/Icon';

export interface Props
  extends React.ButtonHTMLAttributes<HTMLButtonElement & HTMLAnchorElement> {
  iconTitle: string;
  icon: string;
  link?: string;
  secondary?: boolean;
  className?: string;
}

const IconButton: React.FC<Props> = ({
  iconTitle,
  link,
  icon,
  secondary,
  className,
  ...rest
}: Props) => {
  const classNames = classnames({
    'icon-button': true,
    'icon-button--secondary': secondary,
    ...(className ? { [className]: true } : {}),
  });

  let typeIs = rest.type;
  if (!rest.type && !link) {
    typeIs = 'button';
  }

  return !link ? (
    <button {...rest} className={classNames} type={typeIs}>
      <Icon icon={icon} title={iconTitle} className="icon-button__icon" />
    </button>
  ) : (
    <a href={link} className={classNames}>
      <Icon icon={icon} title={iconTitle} className="icon-button__icon" />
    </a>
  );
};

export default IconButton;
