/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable indent */
/* eslint-disable arrow-parens */
/* eslint-disable arrow-body-style */
import React, { useCallback, useEffect, useState } from 'react';
import { Config, MapLocation } from '../types';
import useMediaQuery from '../utils/useMediaQuery';
import LocationSlider from './LocationSlider';
import Locations from './Locations';
import LocationFilter from './LocationFilter';

export type Props = {
  config: Config;
};

const ListLink = ({ link, text }: { link: string; text?: string }) => (
  <div className="filter-map__link">
    <a className="link" href={link}>
      {text || 'Liste mit Orten'}
    </a>
  </div>
);

const FilterMap = ({ config }: Props): JSX.Element => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const [activeIndex, setActiveIndex] = React.useState<number>();
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();
  const [markers, setMarkers] = useState<
    Array<MapLocation & { index: number }>
  >([]);
  const [initialLocations, setInitialLocations] = useState<
    Array<MapLocation & { index: number }>
  >([]);
  const [activeFilter, setActiveFilter] = useState('all');

  const elementRef = useCallback(node => {
    if (!node) return;
    setHeight(node?.clientHeight);
    setWidth(node?.clientWidth);
    const resizeObserver = new ResizeObserver(() => {
      setHeight(node?.clientHeight);
      setWidth(node?.clientWidth);
    });
    resizeObserver.observe(node);
  }, []);

  useEffect(() => {
    setInitialLocations(
      config.locations
        ? config.locations?.map((location, i) => ({
            ...location,
            index: i,
          }))
        : [],
    );
    setActiveIndex(!isDesktop ? 0 : undefined);
  }, [config.locations, isDesktop]);

  useEffect(() => {
    setMarkers(initialLocations);
  }, [initialLocations]);

  const { headline } = Object.assign(
    {
      headline: {
        title: 'Verbreitung <br/> Mobbing&Du',
        subtitle: 'in Baden-Württemberg',
      },
      filter: [
        {
          text: 'Grundschulen',
          value: 'preschool',
          tooltip: 'Grundschule',
          tooltipPlural: 'Grundschulen',
        },
        {
          text: 'Weiterführende Schulen',
          value: 'highschool',
          tooltip: 'weiterführende Schule',
          tooltipPlural: 'weiterführende Schulen',
        },
      ],
    },
    config,
  );

  const handleFilterChange = (filter: string) => {
    setActiveFilter(filter);

    if (filter === 'all') {
      setMarkers(initialLocations);
      return;
    }

    const filteredLocations =
      initialLocations?.filter(location => location.filter?.[filter]) || [];
    setMarkers(filteredLocations);
    setActiveIndex(undefined);
  };

  const handleLocationChange = (i?: number) => {
    setActiveIndex(i);
  };

  return (
    <div className="filter-map__inner">
      <div className="filter-map__content">
        <h2 className="headline headline--2">
          <span
            className="f-w-bold"
            dangerouslySetInnerHTML={{ __html: headline.title }}
          />
          <br />
          <span dangerouslySetInnerHTML={{ __html: headline.subtitle }} />
        </h2>

        {isDesktop && (
          <LocationFilter
            onFilterChange={handleFilterChange}
            filter={config.filter || []}
            activeFilter={activeFilter}
          />
        )}

        {config.listLink?.link && isDesktop && (
          <ListLink
            link={config.listLink.link}
            text={config.listLink.text || 'Teilnehmende Orte als Liste'}
          />
        )}
      </div>

      <div className="filter-map__container" ref={elementRef}>
        <img
          src={
            isDesktop
              ? `${config.assetUrl || ''}/images/mud/mud-map-desktop.svg`
              : `${config.assetUrl || ''}/images/mud/mud-map-mobile.svg`
          }
          alt="Karte von Baden-Württemberg"
        />

        <Locations
          locations={markers}
          onLocationChange={handleLocationChange}
          activeIndex={activeIndex}
          isDesktop={isDesktop}
          containerHeight={height}
          containerWidth={width}
          activeFilter={activeFilter}
          filterConfig={config.filter}
        />
      </div>

      {!isDesktop && (
        <LocationSlider
          locations={config.locations}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          filter={config.filter}
        />
      )}

      {config.listLink?.link && !isDesktop && (
        <ListLink
          link={config.listLink.link}
          text={config.listLink.mobileText || 'Liste mit Orten'}
        />
      )}
    </div>
  );
};

export default FilterMap;
