/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-parens */
import classNames from 'classnames';
import React, { FC, useCallback } from 'react';
import TooltipLite, { TooltipProps } from 'react-tooltip-lite';
import Icon from '../../../../_particles/icon/react/Icon';

export interface Props extends TooltipProps {
  content: React.ReactNode;
  children: React.ReactNode;
  isOpen: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  className?: string;
  wrapperClassName?: string;
}

const Tooltip: FC<Props> = ({
  content,
  children,
  isOpen,
  onOpen,
  onClose,
  wrapperClassName,
  className,
  ...rest
}: Props) => {
  const callbackRef = useCallback(closeButton => {
    if (closeButton) {
      setTimeout(() => closeButton.focus(), 500);
    }
  }, []);

  const onToggle = () => {
    if (isOpen) {
      onClose?.();
    } else {
      onOpen?.();
    }
  };

  return (
    <TooltipLite
      className={wrapperClassName}
      content={
        <div className="r-tooltip__inner">
          {content}
          <button
            ref={callbackRef}
            onClick={onClose}
            type="button"
            className="r-tooltip__close"
          >
            <Icon icon="close" />
          </button>
        </div>
      }
      direction="up"
      tipContentClassName={classNames('r-tooltip', className)}
      hoverDelay={500}
      eventToggle="onClick"
      onToggle={onToggle}
      isOpen={isOpen}
      {...rest}
    >
      {children}
    </TooltipLite>
  );
};

export default Tooltip;
